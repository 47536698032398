
import React from "react"
import { graphql } from "gatsby"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import NavBarPadding from "../components/NavBarPadding"

const ProjectItems = ({ data, location }) => {

  return (
    <Layout data={data.meta} location={location}>
    <SiteMetadata
        title="Project"
        description="Project template"
        image=""
      />
      <NavBarPadding>
        <div className="h-screen">
          <h1>Portfolio</h1>
        </div>
      </NavBarPadding>
    </Layout>
  )
}

export default ProjectItems

export const query = graphql`
  query ProjectItemEnQuery {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
